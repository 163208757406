import * as Sentry from '@sentry/nextjs';
import type { NextPage } from 'next';
import type { ErrorProps } from 'next/error';
import Error from 'next/error';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const CustomErrorComponent: NextPage<ErrorProps> = ({ statusCode }) => {
  const router = useRouter();

  useEffect(() => {
    router.replace(statusCode ? `/p-error?code=${statusCode}` : `/p-error`);
  }, [router, statusCode]);

  return null;
};

CustomErrorComponent.getInitialProps = async (contextData) => {
  await Sentry.captureUnderscoreErrorException(contextData);
  return Error.getInitialProps(contextData);
};

export default CustomErrorComponent;
